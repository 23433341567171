import React from 'react';
import { RECEIPT_TYPE_ITEM, RECEIPT_TYPE_MODIFIER } from './PosReceipt/components/PosReceiptItem';

function ModifiedItemRow(props) {

  const {currentTable, tableList, setTableList, modifiedItems } = props;
  
  const modifiedItemsList = modifiedItems.map((modifiedItem) =>
    <ModfiedItemTile 
      key={modifiedItem.key}
      id={modifiedItem.key}
      currentTable={currentTable} 
      name={modifiedItem.name} 
      limit={modifiedItem.limit}
      price={modifiedItem.price}
      category={modifiedItem.category}
      tableList={tableList}
      setTableList={setTableList}
    /> 
  )

  return (
    <>
      <div className="row mr-minus-10">
        {modifiedItemsList}
      </div>
    </>
  );
}

function ModfiedItemTile(props) {

  const {id, currentTable, name, price, category, limit, tableList, setTableList } = props;
  const posItemsActive = tableList[currentTable - 1].posItemsActive;

  function bogofBeerCheck() {
    const numberOfBeers = tableList[currentTable - 1].items.reduce((sum, item) => {
      if (item.name === "beer") {
        return sum + item.quantity;
      }
      return sum;
    }, 0);
    const numberOfBeerBogofs = tableList[currentTable - 1].items.reduce((sum, item) => {
      if (item.modifiers.find(modifier => modifier.category === "bogof")) {
        return sum + 1;
      }
      return sum;
    }, 0);
    return numberOfBeers > 1 && numberOfBeerBogofs < Math.floor(numberOfBeers/2);
  }

  function addPosReceiptItemModifier() {
    if (posItemsActive && !modifierCountMeetsLimit() && !bogofCountMeetsLimit() && !modifierExceedsUniqueCategoryLimit()) {

      const updatedTable = tableList[currentTable - 1];
      let modifierPrice = price;
      if (category === "bogof") {
        modifierPrice = -updatedTable.currentItem.price;
      }

      const itemToAdd = updatedTable.items[updatedTable.currentItem.index];
      let existingModifiedItem = false

      // Search the list of modifiers to find if the item exists and update the quantity 
      itemToAdd.modifiers.forEach((modifiedItem, index) => {
        if (modifiedItem.name === name && itemToAdd.modifiers.length > 0) {
          itemToAdd.modifiers[index].quantity = itemToAdd.modifiers[index].quantity + 1;
          existingModifiedItem = true;
        }
      });

      // Add a completely new row to the modifier list if the item is not currently in the list
      if (!existingModifiedItem) {
        itemToAdd.modifiers.push({
          type: RECEIPT_TYPE_MODIFIER,
          name: name, 
          quantity: 1, 
          price: modifierPrice, 
          category: category, 
          key: id
        })
      }

      // Split up items where quantity is more than one and we have just added a modifier
      if (updatedTable.currentItem.quantity > 1 && itemToAdd.modifiers.length > 0) {

        // console.log("CurrentItem", currentItem, "ItemToAdd", itemToAdd, "Price", price, "ModifierPrice", modifierPrice);
        
        updatedTable.items.push({
          type: RECEIPT_TYPE_ITEM,
          name: itemToAdd.name, 
          quantity: itemToAdd.quantity - 1, 
          price: updatedTable.currentItem.price, 
          category: itemToAdd.category, 
          key: itemToAdd.key,
          modifiers: [],
        })

        itemToAdd.quantity = 1;
        updatedTable.currentItem.quantity = 1;
      }

      itemToAdd.price = itemToAdd.price + modifierPrice;

      // Update the amounts to reflect the price of the new modifier 
      addPriceToTotals(updatedTable, modifierPrice);
      setTableList([...tableList]);
    }
  }

  function addPriceToTotals(updatedTable, itemPrice) {
    updatedTable.subTotal += itemPrice;
    updatedTable.total += itemPrice;
    modifyItemWithServiceCharge(updatedTable, itemPrice);
  }

  function modifyItemWithServiceCharge(updatedTable, itemPrice) {
    if (currentTable === 5 || currentTable === 8) {
      const serviceCharge = Math.floor(itemPrice / 10);
      updatedTable.serviceCharge += serviceCharge;
      updatedTable.total += serviceCharge; 
    }
  }

  function bogofCountMeetsLimit() {
    return category === "bogof" && !bogofBeerCheck();
  }

  function modifierCountMeetsLimit() {
    return limit !== undefined && limit === 1 &&
      tableList[currentTable - 1].items[tableList[currentTable - 1].currentItem.index]?.modifiers.find(modifier => modifier.name === name);
  }

  function modifierExceedsUniqueCategoryLimit() { 
    return tableList[currentTable - 1].items[tableList[currentTable - 1].currentItem.index]?.modifiers.filter(modifier => {
        return modifier.name !== name && modifier.category === category
      }).length > 0;
  }

  return (
    <>
      <div className="col-xs-4 products__product"
          onClick={() => addPosReceiptItemModifier()}>
        <div className={`product-click-container ${posItemsActive === false 
          || modifierCountMeetsLimit()
          || modifierExceedsUniqueCategoryLimit()
          || bogofCountMeetsLimit() ? "disabled-button" : ""}`}>
          <div className={`products__product__image-container ${name.replace(/([a-z])([A-Z])/g, "$1-$2")
          .replace(/[\s_]+/g, '-').toLowerCase()}`}>
          </div>
          <div className="products__product__text text-center">
            {name.charAt(0).toUpperCase() + name.substring(1)}
          </div>
        </div>
      </div>
    </>
  );
}

export { ModifiedItemRow };