import React from 'react';
import CurrencyFormat from 'react-currency-format';

function PosReceiptItemDiv(props) {
  const { tableList, setTableList, tLIndex, index, name, price, quantity, category, posItemsActive, clearPreviouslySelectedItemsFromArray } = props;

  const currentItemIsSelected = tableList[tLIndex].items[index].selected;
  const currentItem = tableList[tLIndex].currentItem;

  function updateSelectedItem(e) {
    if (posItemsActive && (currentItem.index === -1 || tableList[tLIndex].items[index].selected === true)) {
      const selectedIndex = parseInt(e.currentTarget.getAttribute('data-id'));
      clearPreviouslySelectedItemsFromArray(tableList[tLIndex].items, index);
      tableList[tLIndex].items[selectedIndex].selected = !currentItemIsSelected;
      tableList[tLIndex].currentItem = {index: currentItemIsSelected ? -1 : selectedIndex, name: name, price: price, quantity: quantity, category: category};
      if (currentItemIsSelected) {
        tableList[tLIndex].modifyItemVisible = false;  
        tableList[tLIndex].currentModifiedItem = {index: -1};
        clearPreviouslySelectedItemsFromArray(tableList[tLIndex].items[index].modifiers);
      }
      setTableList([...tableList]);
    }
  }

  return (
    <div data-id={index}
      className={`receipt__selected-product__container
        ${posItemsActive && (currentItem.index === -1 || tableList[tLIndex].items[index].selected === true) ?
          "removableProduct" : "disabled-button"}
        ${currentItemIsSelected ? "selectedRemovableProduct" : ""}`} 
      onClick={(e) => updateSelectedItem(e)}
    >
      <div className="receipt__selected-product-name pull-left">{`${name.toUpperCase()} ${tableList[tLIndex].items[index].modifiers.length > 0 ? " (M)" : "" }`}</div>
      <div className="receipt__selected-product-price pull-right">
        <CurrencyFormat value={parseFloat(price / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/>
      </div>
      <div className="receipt__selected-product-price pull-right margin-left receipt-item-quantity">
        x {quantity}
      </div>
    </div>
  );
}

function PosReceiptModifiedItemDiv(props) {
  const { tableList, setTableList, tLIndex, parentIndex, index, name, quantity, price, posItemsActive, modifiers, clearPreviouslySelectedItemsFromArray } = props;

  const temp = tableList[tLIndex].items[parentIndex].modifiers[index]?.selected;
  const currentModifiedItemIsSelected = temp ? temp : false;
  const currentItem = tableList[tLIndex].currentItem;

  function updateSelectedModifiedItem(e) {
    if (posItemsActive && currentItem.index === parentIndex) {
      const selectedIndex = parseInt(e.currentTarget.getAttribute('data-id'));
      tableList[tLIndex].modifyItemVisible = true;
      tableList[tLIndex].modifiedItems = modifiers[currentItem.name];
      clearPreviouslySelectedItemsFromArray(tableList[tLIndex].items[parentIndex].modifiers, index);
      tableList[tLIndex].items[parentIndex].modifiers[selectedIndex].selected = !currentModifiedItemIsSelected;
      tableList[tLIndex].currentModifiedItem = {index: currentModifiedItemIsSelected ? -1 : selectedIndex, name: name, price: price, quantity: quantity};
      setTableList([...tableList]);
    }
  }

  return (
    <div data-id={index}
      className={`receipt__selected-product__container
        ${posItemsActive && currentItem.index === parentIndex ? "removableModifiableProduct" : "disabled-button"}
        ${currentModifiedItemIsSelected ? "selectedModifiableRemovableProduct" : ""}
        receipt__modified-product`} 
      onClick={(e) => updateSelectedModifiedItem(e)}
    >
      <div className="receipt__modified-prouct-name receipt__selected-product-name pull-left">{name.toUpperCase()}</div>
      <div className="receipt__selected-product-price pull-right">
        <CurrencyFormat value={parseFloat(price / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/>
      </div>
      <div className="receipt__selected-product-price pull-right margin-left receipt-item-quantity">
        x {quantity}
      </div>
    </div>
  );
}

export { PosReceiptItemDiv, PosReceiptModifiedItemDiv };