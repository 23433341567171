import React, { createContext } from 'react'
import { io } from 'socket.io-client';
import { ENV, PRODUCTION, SERVER_HOST } from './config';
import {  DISCONNECT, getBillItemsResponse, getFullBillResponse, getSesssionResponse, getTableResponse, GET_BILL_ITEMS, GET_FULL_BILL, GET_SESSION, GET_TABLE, listBillItemsResponse, listSesssionsResponse, listTablesResponse, LIST_BILL_ITEMS, LIST_SESSIONS, LIST_TABLES, lockSessionResponse, LOCK_SESSION, recordPaymentResponse, RECORD_PAYMENT, unlockSessionResponse, UNLOCK_SESSION } from './tablesApi';
import axios from 'axios';
import { WS_CONNECTION_STATUS_CONNECTED, WS_CONNECTION_STATUS_DISCONNECTED, WS_CONNECTION_STATUS_FAILED, WS_CONNECTION_STATUS_OPEN } from '../store/state';
const WebSocketContext = createContext(null);

export { WebSocketContext }

export default ({ children }) => {
  let socket;
  let ws;

  async function connectTablesAPI(accountName, apiKey, setWsConnectionStatus) {
    const auth = Buffer.from(accountName + ":" + apiKey).toString('base64');
    const response = await axios.get(`${SERVER_HOST}/connect`, {
      headers: { 
        accountname: accountName,
        authtoken: auth,
        clientSocketId: socket.id
      }
    })
    if (response.data.status === 200) {
      setWsConnectionStatus(WS_CONNECTION_STATUS_CONNECTED)
      if (ENV !== PRODUCTION) {
        console.log("Websocket connection to Connect established:", { accountName: accountName, clientSocketId: socket.id });
      }
    } else {
      setWsConnectionStatus(WS_CONNECTION_STATUS_FAILED)
    }
  }

  function registerWsListeners(tableList, setTableList, completedSessions, setCompletedSessions, paymentList, setPaymentList, closedTableList, setClosedTableList, setWsConnectionStatus, setConnectionError) {
    if (!socket) {
      socket = io(SERVER_HOST)
      if (ENV !== PRODUCTION) {
        console.log(`Opening a ws connection to the server: ${SERVER_HOST}`)
      }
    }

    if(socket) {
      socket.on("connect", () => {
        setWsConnectionStatus(WS_CONNECTION_STATUS_OPEN);
      });
    }

    socket.on("fromConnect", (data) => {
      data = JSON.parse(data);
      switch(data.method) {
        default: break;
        case DISCONNECT:
          if (ENV !== PRODUCTION) {
            console.log("Websocket connection to Connect disconnected:", data);
          }
          setWsConnectionStatus(WS_CONNECTION_STATUS_DISCONNECTED);
          setConnectionError("This EPoS has been disconnected from the Tables API by another user or browser tab. If you still wish to use this EPoS, please ensure you are the only person using this account and reload the page.");
          break;
        case LOCK_SESSION:
          if (ENV !== PRODUCTION) {
            console.log("Lock sessions triggered")
          }
          socket.emit("fromPOS", lockSessionResponse(data, tableList, setTableList));
          break;
        case UNLOCK_SESSION:
          if (ENV !== PRODUCTION) {
            console.log("Unlock sessions triggered")
          }
          socket.emit("fromPOS", unlockSessionResponse(data, tableList, setTableList));
          break;
        case GET_SESSION:
          if (ENV !== PRODUCTION) {
            console.log("Get session triggered")
          }
          socket.emit("fromPOS", getSesssionResponse(data, tableList, completedSessions));
          break;    
        case GET_BILL_ITEMS:
          if (ENV !== PRODUCTION) {
            console.log("Get bill items triggered")
          }
          socket.emit("fromPOS", getBillItemsResponse(data, tableList, completedSessions));
          break;
        case GET_FULL_BILL:
          if (ENV !== PRODUCTION) {
            console.log("Get full bill triggered")
          }
          socket.emit("fromPOS", getFullBillResponse(data, tableList, completedSessions));
          break;
        case LIST_BILL_ITEMS:
          if (ENV !== PRODUCTION) {
            console.log("List bill items triggered")
          }
          socket.emit("fromPOS", listBillItemsResponse(data, tableList));
          break;        
        case LIST_SESSIONS: 
          if (ENV !== PRODUCTION) {
            console.log("List sessions triggered")
          }
          socket.emit("fromPOS", listSesssionsResponse(data, tableList, completedSessions))
          break;
        case GET_TABLE: 
          if (ENV !== PRODUCTION) {
            console.log("Get table triggered")
          }
          socket.emit("fromPOS", getTableResponse(data, tableList));
          break;
        case LIST_TABLES:
          if (ENV !== PRODUCTION) {
            console.log("List tables triggered")
          }
          socket.emit("fromPOS", listTablesResponse(data, tableList));
          break;
        case RECORD_PAYMENT: 
          if (ENV !== PRODUCTION) {
            console.log("Record payment triggered")
          }
          socket.emit("fromPOS", recordPaymentResponse(data, tableList, setTableList, completedSessions, setCompletedSessions, paymentList, setPaymentList, closedTableList, setClosedTableList));
          break;
      }
    }, [])
  }

  ws = {
    socket: socket,
    registerWsListeners,
    connectTablesAPI
  }

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  )
}