import React from 'react';
import { TABLE_STATUS_AVAILABLE, TABLE_STATUS_OCCUPIED } from '../../../store/state';

function Table(props) {

  const {
    setCurrentTable, 
    seats, 
    number, 
    status, 
    isLocked,
    tableList,
    setTableList
  } = props;


  function getTable() {
    switch(status) {
      default: 
      case TABLE_STATUS_AVAILABLE:
        return "free";
      case TABLE_STATUS_OCCUPIED:
        if(isLocked) {
          return "locked";
        } else {
          return "occupied";
        }
    }
  }

  function setPosItemsVisible(bool) {
    tableList[number - 1].posItemsVisible = bool; 
    setTableList([...tableList])
  }

  return (
    <>
      <div 
        id={`table-${number}`} 
        data-table-name={number} 
        className={`restaurant-tables ${seats}-seat-table table-${number} ${getTable()}-table`}
        onClick={() => {
            setCurrentTable(number);
            setPosItemsVisible(true)
          }
        }
      >
        <div className="table-number">{number}</div>
      </div>
    </>
  );
}

export { Table };