import { v4 as uuidv4 } from 'uuid';

export const TABLE_STATUS_NOT_IN_USE = "TABLE_STATUS_NOT_IN_USE";
export const TABLE_STATUS_PENDING_AVAILABLE = "TABLE_STATUS_PENDING_AVAILABLE";
export const TABLE_STATUS_AVAILABLE = "TABLE_STATUS_AVAILABLE";
export const TABLE_STATUS_OCCUPIED = "TABLE_STATUS_OCCUPIED";


export const WS_CONNECTION_STATUS_CLOSED = "WS_CONNECTION_STATUS_CLOSED";
export const WS_CONNECTION_STATUS_FAILED = "WS_CONNECTION_STATUS_FAILED";
export const WS_CONNECTION_STATUS_OPEN = "WS_CONNECTION_STATUS_OPEN";
export const WS_CONNECTION_STATUS_CONNECTED = "WS_CONNECTION_STATUS_CONNECTED";
export const WS_CONNECTION_STATUS_DISCONNECTED = "WS_CONNECTION_STATUS_DISCONNECTED";

export const initialState = {
  wsConnectionStatus: WS_CONNECTION_STATUS_CLOSED,
  accountName: "demo0002",
  apiKey: "Payment1",
  connectionError: "",
  currentTable: 1,
  enterAmount: {
    isVisible: false,
    id: null,
    category: null
  },
  modifiers: {
    "coffee": [
      { key: "coffee-mod-0", name: "Cashew Milk", price: 50, category: "milk", limit: 1 },
      { key: "coffee-mod-1", name: "Coconut Milk", price: 50, category: "milk", limit: 1 },
      { key: "coffee-mod-2", name: "Oat Milk", price: 50, category: "milk", limit: 1 },
      { key: "coffee-mod-3", name: "Small", price: -20, category: "size", limit: 1},
      { key: "coffee-mod-4", name: "Large", price: 50, category: "size", limit: 1 },
      { key: "coffee-mod-5", name: "Extra Large", price: 100, category: "size", limit: 1 },
    ],
    "beer": [
      { key: "beer-mod-0", name: "BOGOF Promo", price: 550, category: "bogof" },
    ],
    "burger": [
      { key: "burger-mod-0", name: "Extra Cheese", price: 80, category: "cheese" },
      { key: "burger-mod-1", name: "Extra Patty", price: 220, category: "patty" },
      { key: "burger-mod-2", name: "French Fries", price: 350, category: "fries" },
      { key: "burger-mod-3", name: "No Bun", price: -150, category: "bun", limit: 1 },
      { key: "burger-mod-4", name: "No Cheese", price: 0, category: "cheese", limit: 1 },
      { key: "burger-mod-5", name: "No Tomatos", price: 0, category: "tomato", limit: 1 },
    ],
    "tea": [
      { key: "tea-mod-0", name: "Cashew Milk", price: 50, category: "milk", limit: 1 },
      { key: "tea-mod-1", name: "Coconut Milk", price: 50, category: "milk", limit: 1 },
      { key: "tea-mod-2", name: "Oat Milk", price: 50, category: "milk", limit: 1 },
      { key: "tea-mod-3", name: "Small", price: -20, category: "size", limit: 1 },
      { key: "tea-mod-4", name: "Large", price: 50, category: "size", limit: 1 },
      { key: "tea-mod-5", name: "Extra Large", price: 100, category: "size", limit: 1 },
    ],
    "pizza": [
      { key: "pizza-mod-0", name: "Vegan Cheese", price: 0, category: "dietary", limit: 1 },
    ],
    "spirit": [
      { key: "spirit-mod-0", name: "Glass", price: -1650, category: "size", limit: 1 },
      { key: "spirit-mod-1", name: "Shot", price: -2150, category: "size", limit: 1 },
    ],
    "wine": [
      { key: "wine-mod-0", name: "Wine Glass", price: -1200, category: "size", limit: 1 },
    ]
  },
  closedTableList: [],
  paymentList: [],
  completedSessions: [],
  tableList: [
    {
      id: "41df7a86-63b3-4722-b0ec-ec996da49945",
      number: 1,
      name: "Table 1",
      seats: "two",
      serviceCharge: 0,
      subTotal: 0,
      total: 0, 
      paid: 0,
      items: [],
      status: TABLE_STATUS_AVAILABLE,
      isLocked: false,
      posItemsActive: true,
      posItemsVisible: false,
      modifyItemVisible: false,
      currentItem: {
        index: -1
      },
      currentModifiedItem: {
        index: -1
      },
      modifiedItems: []
    },
    {
      id: "069c8221-8db3-4aff-8906-85ed99ba7453",
      name: "Table 2",
      number: 2,
      seats: "two",
      serviceCharge: 0,
      subTotal: 0,
      total: 0, 
      paid: 0,
      items: [],
      status: TABLE_STATUS_AVAILABLE,
      isLocked: false,
      posItemsActive: true,
      posItemsVisible: false,
      modifyItemVisible: false,
      currentItem: {
        index: -1
      },
      currentModifiedItem: {
        index: -1
      },
      modifiedItems: []
    },
    {
      id: "8907a296-4a65-468f-8037-496954a54ba0",
      name: "Table 3",
      number: 3,
      seats: "two",
      serviceCharge: 0,
      subTotal: 0,
      total: 0, 
      paid: 0,
      items: [],
      status: TABLE_STATUS_AVAILABLE,
      isLocked: false,
      posItemsActive: true,
      posItemsVisible: false,
      modifyItemVisible: false,
      currentItem: {
        index: -1
      },
      currentModifiedItem: {
        index: -1
      },
      modifiedItems: []
    },
    {
      id: "99f63dbc-67e1-46e0-b519-9479dc7b7c31",
      name: "Table 4",
      number: 4,
      seats: "two",
      serviceCharge: 0,
      subTotal: 0,
      total: 0, 
      paid: 0,
      items: [],
      status: TABLE_STATUS_AVAILABLE,
      isLocked: false,
      posItemsActive: true,
      posItemsVisible: false,
      modifyItemVisible: false,
      currentItem: {
        index: -1
      },
      currentModifiedItem: {
        index: -1
      },
      modifiedItems: []
    },
    {
      id: "28e28d32-a98d-40a9-99c3-22dd1ad8d472",
      name: "Table 5",
      number: 5,
      seats: "eight",
      serviceCharge: 0,
      subTotal: 0,
      total: 0, 
      paid: 0,
      items: [],
      status: TABLE_STATUS_AVAILABLE,
      isLocked: false,
      posItemsActive: true,
      posItemsVisible: false,
      modifyItemVisible: false,
      currentItem: {
        index: -1
      },
      currentModifiedItem: {
        index: -1
      },
      modifiedItems: []
    },
    {
      id: "84855cd2-face-400e-9891-798e7433ef22",
      name: "Table 6",
      number: 6,
      seats: "two",
      serviceCharge: 0,
      subTotal: 0,
      total: 0, 
      paid: 0,
      items: [],
      status: TABLE_STATUS_AVAILABLE,
      isLocked: false,
      posItemsActive: true,
      posItemsVisible: false,
      modifyItemVisible: false,
      currentItem: {
        index: -1
      },
      currentModifiedItem: {
        index: -1
      },
      modifiedItems: []
    },
    {
      id: "fc8652bd-0951-4e09-ae3f-d3a4bf2d63b4",
      name: "Table 7",
      number: 7,
      seats: "two",
      serviceCharge: 0,
      subTotal: 0,
      total: 0, 
      paid: 0,
      items: [],
      status: TABLE_STATUS_AVAILABLE,
      isLocked: false,
      posItemsActive: true,
      posItemsVisible: false,
      modifyItemVisible: false,
      currentItem: {
        index: -1
      },
      currentModifiedItem: {
        index: -1
      },
      modifiedItems: []
    },
    {
      id: "82a204a3-884d-477d-8817-fd5e267b258b",
      name: "Table 8",
      number: 8,
      seats: "six",
      serviceCharge: 0,
      subTotal: 0,
      total: 0, 
      paid: 0,
      items: [],
      status: TABLE_STATUS_AVAILABLE,
      isLocked: false,
      posItemsActive: true,
      posItemsVisible: false,
      modifyItemVisible: false,
      currentItem: {
        index: -1
      },
      currentModifiedItem: {
        index: -1
      },
      modifiedItems: []
    }
  ],
  posItems: [
    { key: 0, name: "coffee", price: 325, category: ["drinks", "caffeine"] },
    { key: 1, name: "chicken", price: 575, category: ["food", "american"] },
    { key: 2, name: "beer", price: 550, category: ["drinks", "alcohol"]},
    { key: 3, name: "tea", price: 300, category: ["drinks", "caffeine"] },
    { key: 4, name: "seafood", price: 840, category: ["food", "mediterranean"] },
    { key: 5, name: "wine", price: 1650, category: ["drinks", "alcohol"] },
    { key: 6, name: "soda", price: 150, category: ["drinks"] },
    { key: 7, name: "burger", price: 600, category: ["food", "american"] },
    { key: 8, name: "spirit", price: 2450, category: ["drinks", "alcohol"] },
    { key: 9, name: "water", price: 100, category: ["drinks"] },
    { key: 10, name: "pizza", price: 875, category: ["food", "italian"] },
    { key: 11, name: "discount", price: -1, category: ["discount"] }
  ]
}

export function setNewUUID() {
  return uuidv4();
}