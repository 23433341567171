import React from 'react';
import { Table } from './components/TableGraphic'
import { ClosedTables } from './components/ClosedTables';
import cafeLogo from '../../images/cafe-logo.png'
import floorPlanEmpty from '../../images/floor-plan-empty.png'
import { WS_CONNECTION_STATUS_CONNECTED } from '../../store/state';

function FloorPlan(props) {

  const {
    wsConnectionStatus, 
    setCurrentTable,
    currentTable, 
    tableList,
    setTableList,
    closedTableList
  } = props;
  const tableItems = tableList.map((table) => 
    <Table 
      key={table.number} 
      number={table.number} 
      seats={table.seats} 
      status={table.status}
      setCurrentTable={setCurrentTable}
      isLocked={table.isLocked}
      tableList={tableList}
      setTableList={setTableList}
    />
  );

  const posItemsVisible = tableList[currentTable - 1].posItemsVisible

  // let currentInnerWidth = 0;
  // let floorPlanWidth = 567; // 587px - 20 padding
  // window.onresize = (event) => { 
  //   currentInnerWidth = event.currentTarget.innerWidth; console.log(currentInnerWidth);
  //   if (currentInnerWidth >= 1200) {
  //     floorPlanWidth = 567;
  //   } else if (currentInnerWidth >= 992 && currentInnerWidth < 1200) {
  //     floorPlanWidth = 424;
  //   } else if (currentInnerWidth < 992) {
  //     floorPlanWidth = 498;
  //   }
  // };

  return (
    <>
      {wsConnectionStatus === WS_CONNECTION_STATUS_CONNECTED && !posItemsVisible &&
        <div className="row floor-plan-mode__container">
          <div className="floor-plan-container col-xs-12 col-sm-12 col-md-8">
            <div className="row">
              <div className="col-xs-12 col-sm-9 col-md-12 pull-right">
                <div className="pat-business-brand-desktop text-center visible-md visible-lg pull-left full-screen-switcher">
                  <div className="">
                    <img src={cafeLogo} alt="Dojo" />
                  </div>
                  <div className="business-brand__client-name">
                    Jan's Diner
                  </div>
                </div>
                <div className="floor-plan-image-container">
                  <div>
                    {/* <img width={floorPlanWidth} src={floorPlanEmpty} alt="Dojo" /> */}
                    <img src={floorPlanEmpty} alt="Dojo" />
                  </div>
                  <div className="floor-plan-tables-container">
                    {tableItems}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 col-md-12 floor-plan-circles-container pull-left">
                <div className="row">
                  <div className="floor-plan-circles-container__circle-container col-xs-4 col-sm-12 col-md-3 text-center">
                    <div className="floor-plan-circles-container__circle red"></div>
                    <div className="floor-plan-circles-container__circle-text">Occupied <span className="floor-plan-circles-container__circle-text__span visible-sm visible-md visible-lg">table</span></div>
                  </div>
                  <div className="floor-plan-circles-container__circle-container col-xs-4 col-sm-12 col-md-3 text-center">
                    <div className="floor-plan-circles-container__circle green"></div>
                    <div className="floor-plan-circles-container__circle-text">Free <span className="floor-plan-circles-container__circle-text__span visible-sm visible-md visible-lg">table</span></div>
                  </div>
                  <div className="floor-plan-circles-container__circle-container col-xs-4 col-sm-12 col-md-3 text-center">
                    <div className="floor-plan-circles-container__circle white"></div>
                    <div className="floor-plan-circles-container__circle-text">Locked <span className="floor-plan-circles-container__circle-text__span visible-sm visible-md visible-lg">table</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ClosedTables closedTableList={closedTableList} />
        </div>
      }
    </>
  );
}

export { FloorPlan };