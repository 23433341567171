import React from 'react';
import { TABLE_STATUS_OCCUPIED } from '../../../store/state';
import { RECEIPT_TYPE_ITEM, RECEIPT_TYPE_MODIFIER } from './PosReceipt/components/PosReceiptItem';

function PosButtons(props) {

  const { tableList, currentTable, setTableList, modifiers, clearPreviouslySelectedItemsFromArray } = props;
  const currentItem = tableList[currentTable - 1].currentItem;
  const currentModifiedItem = tableList[currentTable - 1].currentModifiedItem;

  const modifiableItems = Object.keys(modifiers)
  // console.log("Current Item", currentItem, "Current Modified Item", currentModifiedItem)

  const posItemsActive = tableList[currentTable - 1].posItemsActive;
  const noOfTableItems = tableList[currentTable - 1].items ? tableList[currentTable - 1].items.length : 0;
  const modifyItemVisible = tableList[currentTable - 1].modifyItemVisible;

  function bogofCheck() {
    const numberOfItem = tableList[currentTable - 1].items.reduce((sum, item) => {
      if (item.name === currentItem.name) {
        return sum + item.quantity;
      }
      return sum;
    }, 0);
    const numberOfItemBogofs = tableList[currentTable - 1].items.reduce((sum, item) => {
      if (item.modifiers.find(modifier => modifier.category === "bogof")) {
        return sum + 1;
      }
      return sum;
    }, 0);
    return numberOfItemBogofs <= Math.floor((numberOfItem - 1 )/ 2);
  }

  function removePosReceiptItem() {
    const updatedTable = tableList[currentTable - 1];

    // Check if we're removing a top level item OR a modified item
    if (currentModifiedItem.index === -1) {  
      const itemToDelete = updatedTable.items[currentItem.index];
      // console.log("[BEFORE] Remove Pos Item", itemToDelete, "Current Item Index", currentItem.index, "Current Modified Item Index", currentModifiedItem.index) 
      removeItem(updatedTable, itemToDelete, updatedTable.items, currentItem.index);
      // console.log("[AFTER] Remove Pos Item", itemToDelete, "Current Item Index", currentItem.index, "Current Modified Item Index", currentModifiedItem.index) 
    } else {
      const itemToDelete = updatedTable.items[currentItem.index].modifiers[currentModifiedItem.index];
      // console.log("[BEFORE] Remove Pos Item", itemToDelete, "Current Item Index", currentItem.index, "Current Modified Item Index", currentModifiedItem.index) 
      removeItem(updatedTable, itemToDelete, updatedTable.items[currentItem.index].modifiers, currentModifiedItem.index);
      // console.log("[AFTER] Remove Pos Item", itemToDelete, "Current Item Index", currentItem.index, "Current Modified Item Index", currentModifiedItem.index) 
    }


  }

  function modifiersCheck() {
    return modifiers[currentItem.name] ? modifiers[currentItem.name]?.find(mod => mod.category === "bogof") : true; 
  }

  function removeItem(updatedTable, itemToDelete, arrayToDeleteFrom, spliceIndex) {
    // Deleting exclusively where one of the items has a BOGOF modifier 
    let afterRemovingBogof = false;
    if (itemToDelete.type === RECEIPT_TYPE_ITEM && modifiersCheck()) { 
      const bogofCheckPasses = bogofCheck();

      if (!bogofCheckPasses) {
        let indexOfBogofMod = -1; 
        const indexOfBogofItem = updatedTable.items.findIndex(item => {
          indexOfBogofMod = item.modifiers.findIndex(mod => mod.category === "bogof");
          return indexOfBogofMod !== -1;
        });

        const item = updatedTable.items[indexOfBogofItem];
        const mod = item.modifiers[indexOfBogofMod];
    
        if (mod.quantity > 1) {
          mod.quantity = mod.quantity - 1;
        } else {
          item.modifiers.splice(indexOfBogofMod, 1);
          item.price = item.price - mod.price;
          removePriceFromTotals(currentTable, updatedTable, mod.price);
          mergeDuplicateItems(updatedTable);
          afterRemovingBogof = true;
        }
      }
    }

    // Deleting where the item has more than 1 quantity 
    if (itemToDelete.quantity > 1) {
      itemToDelete.quantity -= 1;
      if (itemToDelete.type === RECEIPT_TYPE_MODIFIER) {
        updatedTable.items[currentItem.index].price -= itemToDelete.price;
      }
    } else {

      // Deleting where the item is a receipt item
      if(itemToDelete.type === RECEIPT_TYPE_ITEM) {
        if (itemToDelete.modifiers.length === 0) {
          tableList[currentTable - 1].currentModifiedItem = {index: -1};
        }
        setModifyItemVisible(tableList, currentTable, false, setTableList);
      } 

      arrayToDeleteFrom.splice(spliceIndex, 1);

      // Deleting where the item is a modifier that is being deleted   
      if (itemToDelete.type === RECEIPT_TYPE_MODIFIER) {
        updatedTable.items[currentItem.index].price -= itemToDelete.price;
        if (updatedTable.items[currentItem.index].modifiers.length === 0) {
          mergeDuplicateItems(updatedTable);
        }
        afterRemovingBogof = itemToDelete.category === "bogof" ? true : false;
      }

      if (currentModifiedItem.index === -1) { 
        tableList[currentTable - 1].currentItem = { index: -1 }
      } else {
        tableList[currentTable - 1].currentModifiedItem = { index: -1 }
      }
    }

    if (afterRemovingBogof) {
      clearPreviouslySelectedItemsFromArray(updatedTable.items, -1)
      setModifyItemVisible(tableList, currentTable, false, setTableList);
      tableList[currentTable - 1].currentItem = {index: -1};
    }
  
    removePriceFromTotals(currentTable, updatedTable, itemToDelete.price);
    setTableList([...tableList]);
  }

  function mergeDuplicateItems(updatedTable) {
    const duplicateItemIndex = updatedTable.items.findIndex((item, index) => {
      return item.name === currentItem.name && item.modifiers.length === 0 && index !== currentItem.index;
    })
    if (duplicateItemIndex !== -1) {
      const duplicateItem = getItemFromCurrentTable(duplicateItemIndex);
      currentItem.quantity += duplicateItem.quantity;
      tableList[currentTable - 1].items[currentItem.index].quantity += duplicateItem.quantity;
      updatedTable.items.splice(duplicateItemIndex, 1);
      currentItem.index = findItemIndexByNameAndQuantity(updatedTable.items, currentItem.name, currentItem.quantity)
    }
  }

  function findItemIndexByNameAndQuantity(array, name, quantity) {
    return array.findIndex(item => item.name === name && item.quantity === quantity);
  }

  function getItemFromCurrentTable(itemIndex){
    return tableList[currentTable - 1].items[itemIndex];
  }

  function setTableOccupiedWithItems() {
    tableList[currentTable - 1].status = TABLE_STATUS_OCCUPIED;
    tableList[currentTable - 1].posItemsActive = false;
    tableList[currentTable - 1].posItemsVisible = false; 
    setTableList([...tableList]);
  }

  function releasePosHold() {
    tableList[currentTable - 1].posItemsActive = true;
    setTableList([...tableList]);
  }

  function toggleModifiableOptions() {
    if (modifiableItems.includes(currentItem.name)) {
      tableList[currentTable - 1].modifiedItems = modifiers[currentItem.name];
      setModifyItemVisible(tableList, currentTable, !modifyItemVisible, setTableList);
    }
  }

  return (
    <>
      <div className="row">
        <div className="buttons col-xs-12 col-sm-12 col-md-12 pull-right">
          <div className="row mr-minus-10">
            <div className="col-xs-4 buttons__button__container">
              {posItemsActive &&
                <button
                  className={`buttons__button button__cancel-transaction ${noOfTableItems === 0 ? "disabled-button" : ""}`}
                  disabled={noOfTableItems === 0 ? true : false}
                  type="button"
                  onClick={() => setTableOccupiedWithItems()}>
                  Hold
                </button>
              }
              {!posItemsActive &&
                <button
                  className={`buttons__button button__cancel-transaction ${noOfTableItems === 0 ? "disabled-button" : ""}`}
                  disabled={noOfTableItems === 0 ? true : false}
                  type="button"
                  onClick={() => releasePosHold()}>
                  Release
                </button>
              }
            </div>
            <div className="col-xs-4 buttons__button__container">
              {!modifyItemVisible &&
                <button
                  className={`buttons__button button__modify ${currentItem.index !== -1 && 
                    modifiableItems.includes(currentItem.name) ? "" : "disabled-button"}`} disabled={currentItem.index === -1 ? true : false}
                  id="removeItemButton"
                  type="button"
                  onClick={() => toggleModifiableOptions()}>
                  Modify Item
                </button>
              }
              {modifyItemVisible &&
                <button
                  className={`buttons__button button__modify`}
                  id="removeItemButton"
                  type="button"
                  onClick={() => {
                    setModifyItemVisible(tableList, currentTable, false, setTableList)
                    clearPreviouslySelectedItemsFromArray(tableList[currentTable - 1].items[currentItem.index].modifiers, -1)
                  }}>
                  Back
                </button>
              }
            </div>
            <div className="col-xs-4 buttons__button__container">
              <button
                className={`buttons__button button__remove ${currentItem.index === -1 ? "disabled-button" : ""}`} disabled={currentItem.index === -1 ? true : false}
                id="removeItemButton"
                type="button"
                onClick={() => removePosReceiptItem()}>
                Remove Item
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function removePriceFromTotals(currentTable, updatedTable, itemPrice) {
  updatedTable.subTotal -= itemPrice;
  updatedTable.total -= itemPrice;
  removeItemWithServiceCharge(currentTable, updatedTable, itemPrice);
}

function removeItemWithServiceCharge(currentTable, updatedTable, itemPrice) {
  if (currentTable === 5 || currentTable === 8) {
    const serviceCharge = Math.floor(itemPrice / 10);
    updatedTable.serviceCharge -= serviceCharge;
    updatedTable.total -= serviceCharge; 
  }
}

function setModifyItemVisible(tableList, currentTable, bool, setTableList) {
  tableList[currentTable - 1].modifyItemVisible = bool; 
  setTableList([...tableList])
}

export { PosButtons, removePriceFromTotals, removeItemWithServiceCharge, setModifyItemVisible };