import React, { useContext, useEffect, useRef, useState } from 'react';
import './App.css';
import './pos.css';
import cafeLogo from '../images/cafe-logo.png'
import { Provider, useSelector } from 'react-redux'
import store from '../store/store';
import { FloorPlan } from './FloorPlan/FloorPlanView';
import { PosItemSelection } from './PosItemSelection/PosItemSelectionView';
import WebSocketProvider, { WebSocketContext } from '../websocket/wsClient';
import { Footer } from './Footer/Footer';
import { useQueryParam, StringParam, QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter } from 'react-router-dom';
import { initialState, WS_CONNECTION_STATUS_CLOSED, WS_CONNECTION_STATUS_CONNECTED, WS_CONNECTION_STATUS_DISCONNECTED, WS_CONNECTION_STATUS_FAILED, WS_CONNECTION_STATUS_OPEN } from '../store/state';
import { ModalEnterAmount } from './PosItemSelection/components/ModalEnterAmount';

function HomeComponent() {
  const [accountName, setAccountName] = useQueryParam('accountName', StringParam);
  const [apiKey, setApiKey] = useQueryParam('apiKey', StringParam);
  const [wsConnectionStatus, setWsConnectionStatus] = useState(store.getState().wsConnectionStatus);
  const [completedSessions, setCompletedSessions] = useState(store.getState().completedSessions);
  const [enterAmount, setEnterAmount] = useState(store.getState().enterAmount);
  const [currentTable, setCurrentTable] = useState(store.getState().currentTable);
  const [tableList, setTableList] = useState(store.getState().tableList);
  const [paymentList, setPaymentList] = useState(store.getState().paymentList);
  const [modifiers] = useState(store.getState().modifiers);
  const [closedTableList, setClosedTableList] = useState(store.getState().closedTableList);
  const posItems = useSelector(state => state.posItems);
  const [connectionError, setConnectionError] = useState(store.getState().connectionError);

  const ws = useContext(WebSocketContext);
  const firstRender = useRef(true);

  if (wsConnectionStatus === WS_CONNECTION_STATUS_OPEN && accountName && apiKey) {
    ws.connectTablesAPI(accountName, apiKey, setWsConnectionStatus);
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      ws.registerWsListeners(tableList, setTableList, completedSessions, setCompletedSessions, paymentList, setPaymentList, closedTableList, setClosedTableList, setWsConnectionStatus, setConnectionError);

      if (wsConnectionStatus !== WS_CONNECTION_STATUS_CONNECTED && !(accountName && apiKey)) {
        setAccountName(initialState.accountName)
        setApiKey(initialState.apiKey)
        const url = new URL(window.location.origin);
        url.searchParams.set('accountName', initialState.accountName);
        url.searchParams.set('apiKey', initialState.apiKey);
        window.location.assign(url.search);
      }
    }
  }, [ws, tableList, completedSessions, paymentList, closedTableList, accountName, apiKey, setAccountName, setApiKey, setConnectionError, wsConnectionStatus])

  return (
    <>
      <div className="cafe-body floor-plan-body">

        <div id="errorContainer" className="container error-container">
          <div className="alert alert-danger fade in error-alert">
            <a href="/" className="close" data-dismiss="alert" aria-label="close" title="close">×</a>
            <ul id="errorText"></ul>
          </div>
        </div>

        <div id="mainContainer" className="container container__customize">
          <div className="row">
            <div className="col-xs-12 col-sm-12 visible-xs visible-sm">
              <div className="business-brand text-center full-screen-switcher">
                <div className="business-brand__client-logo">
                  <img src={cafeLogo} alt="Dojo" />
                </div>
                <div className="business-brand__client-name">
                  Jan's Diner
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="">

                {wsConnectionStatus !== WS_CONNECTION_STATUS_CONNECTED &&
                  <div>
                    <div id="errorContainer" className="error-container" style={{ display: 'block' }}>
                      <div className="alert alert-danger fade in error-alert">
                        {wsConnectionStatus === WS_CONNECTION_STATUS_DISCONNECTED &&
                          <div id="errorText">{connectionError}</div>
                        }
                        {wsConnectionStatus === WS_CONNECTION_STATUS_FAILED &&
                          <div id="errorText">
                            <b>This EPoS cannot communicate with the Tables API.</b><br />
                            Please ensure that the <b>accountName</b> and <b>apiKey</b> in the URL are correct. If the problem persists, please contact support.
                          </div>
                        }
                        {wsConnectionStatus === WS_CONNECTION_STATUS_CLOSED &&
                          <div id="errorText">
                            <b>This EPoS cannot communicate with the Tables API.</b><br />
                            If the problem persists, please contact support.
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }

                <FloorPlan
                  wsConnectionStatus={wsConnectionStatus}
                  currentTable={currentTable}
                  setCurrentTable={setCurrentTable}
                  tableList={tableList}
                  setTableList={setTableList}
                  closedTableList={closedTableList}
                />

                <PosItemSelection
                  wsConnectionStatus={wsConnectionStatus}
                  currentTable={currentTable}
                  posItems={posItems}
                  tableList={tableList}
                  setTableList={setTableList}
                  cafeLogo={cafeLogo}
                  paymentList={paymentList}
                  modifiers={modifiers}
                  enterAmount={enterAmount}
                  setEnterAmount={setEnterAmount}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalEnterAmount
          currentTable={currentTable}
          posItems={posItems}
          tableList={tableList}
          setTableList={setTableList}
          enterAmount={enterAmount}
          setEnterAmount={setEnterAmount}
        />
        <Footer displayMode={0} />
        <Footer displayMode={1} />
      </div>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <WebSocketProvider>
            <div className="App">
              <HomeComponent />
            </div>
          </WebSocketProvider>
        </QueryParamProvider>
      </Provider>
    </BrowserRouter>
  )
}

export default App;
