import React from 'react';
import CurrencyFormat from 'react-currency-format';

function CompletedTransactions(props) {
  const { paymentList, currentTable } = props;

  const completedTransactions = paymentList.filter(tx => tx.tableNumber === currentTable);
  const txs = completedTransactions.map((tx, index) => {
    return (
      <tr key={index}>
        <td>{tx.authCode}</td>
        <td><CurrencyFormat value={parseFloat(tx.baseAmount / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/></td>
        <td><CurrencyFormat value={parseFloat(tx.gratuityAmount / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/></td>
        <td>{tx.paymentMethod}</td>
        <td>{tx.date.substring(11, 16)}</td>
      </tr>
    );
  });

  return (
    <>
      { completedTransactions.length > 0 &&
        <div className="closed-tables-transactions-container">
          <div className="transactions__transactions-header text-center">
            Completed Transactions
          </div>
          <div className="transactions__transactions-table-container">
            <table id="closed-tables-transactions" className="transactions__transactions-table table text-center">
              <thead>
                <tr>
                  <th>Auth Code</th>
                  <th>Amount Paid</th>
                  <th>Gratuity Amount</th>
                  <th>Payment Method</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {txs}
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  );
}

export { CompletedTransactions }