import React from 'react';

import { PosItemRow } from './components/PosItemTile';
import { PosReceipt } from './components/PosReceipt/PosReceiptView';
import { CompletedTransactions } from './components/CompletedTransactions';
import { PosButtons } from './components/PosButtons';
import { WS_CONNECTION_STATUS_CONNECTED } from '../../store/state';
import { ModifiedItemRow } from './components/ModifiedItemTile';

function PosItemSelection(props) {

  const {wsConnectionStatus, currentTable, tableList, setTableList, posItems, cafeLogo, paymentList, modifiers, enterAmount, setEnterAmount } = props;

  const modifiedItems = tableList[currentTable - 1].modifiedItems;

  function clearPreviouslySelectedItemsFromArray(array, index) {
      array.forEach((modifiedItem, i) => {
        if (i !== index) {
          modifiedItem.selected = false;
        }
      })
      setTableList([...tableList]);
    }

  const posItemList = posItems.map((posItem, index) => {
    return (index % 3 === 0) ? <PosItemRow
      key={posItem.key}
      currentTable={currentTable} 
      tableList={tableList}
      setTableList={setTableList}
      posItems={posItems.slice(index, index+3)}
      enterAmount={enterAmount}
      setEnterAmount={setEnterAmount}
    /> : "";
  })

  const modifiedItemsList = modifiedItems.map((posItem, index) => {
    return (index % 3 === 0) ? <ModifiedItemRow
      key={posItem.key}
      currentTable={currentTable} 
      tableList={tableList}
      setTableList={setTableList}
      modifiedItems={modifiedItems.slice(index, index+3)}
    /> : "";
  })

  return (
    <>
      {wsConnectionStatus === WS_CONNECTION_STATUS_CONNECTED && tableList[currentTable - 1].posItemsVisible === true &&
        <div className="table-details-mode__container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-3 visible-md visible-lg">
            <div className="business-brand pat-business-brand-desktop text-center">
              <div className="business-brand__client-logo">
                <img src={cafeLogo} alt="Dojo" />
              </div>
              <div className="business-brand__client-name">
                Jan's Diner
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-9">
            <div className="row section-name-row">
              <div className="section-name-text pull-left">
                Table {currentTable}
              </div>
              <div className="mode-close-button pull-right pointer" onClick={() => {
                  tableList[currentTable - 1].posItemsVisible = false; 
                  tableList[currentTable - 1].modifyItemVisible = false;
                  setTableList([...tableList])
                }}>
                X
              </div>
            </div>
            <div className="f5f5f5-bg pd10">
              <div className="row receipt-and-products-container">
                <PosReceipt 
                  tableList={tableList} 
                  setTableList={setTableList}
                  currentTable={currentTable}
                  modifiers={modifiers}
                  clearPreviouslySelectedItemsFromArray={clearPreviouslySelectedItemsFromArray}
                />   
                <div className="col-xs-12 col-sm-7 col-md-7 custom-products-row">
                  <div className="products">
                    <PosButtons 
                      tableList={tableList}
                      currentTable={currentTable}
                      setTableList={setTableList}
                      modifiers={modifiers}
                      clearPreviouslySelectedItemsFromArray={clearPreviouslySelectedItemsFromArray}
                    />
                    {!tableList[currentTable - 1].modifyItemVisible === true && posItemList}
                    {tableList[currentTable - 1].modifyItemVisible === true && modifiedItemsList}
                  </div>   
                  <CompletedTransactions
                    paymentList={paymentList} 
                    currentTable={currentTable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </>
  );
}

export { PosItemSelection };