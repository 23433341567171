import React from 'react';
import dojoLogo from '../../images/logo.png'

function Footer(props) {

  const { displayMode } = props;
  return (
    <>
      {displayMode === 0 &&
        <div className="footer visible-md visible-lg">
          <img src={dojoLogo} height="70" alt="Dojo" />
        </div>
      }
      {displayMode === 1 &&
        <div style={{ "position": "relative" }} className={"footer visible-xs visible-sm"}>
          <img src={dojoLogo} height="70" alt="Dojo" />
        </div>
      }
    </>
  );
}

export { Footer };