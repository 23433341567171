import React from 'react';
import CurrencyFormat from 'react-currency-format';

function ClosedTables(props) {
  const { closedTableList } = props;

  let tables = closedTableList.map((table, i) => {
    return (
      <tr key={i} className={`${i % 2 === 0 ? "blue-row" : ""}`}>
        <td>{table.time.substring(11, 16)}</td>
        <td>{table.table}</td>
        <td>
          <CurrencyFormat 
            value={parseFloat(table.balance / 100)} 
            prefix={'£'} 
            displayType={'text'} 
            fixedDecimalScale={true} 
            decimalScale={2}/>
        </td>
      </tr>
    );
  });
  for (let i = closedTableList.length; i < 12; i++) {
    tables.push(
      <tr key={i} className={`${i % 2 === 0 ? "blue-row" : ""}`}>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
    );
  }

  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-4 custom-products-row">
        <div className="transactions__transactions-header text-center">
          Closed Tables
        </div>
        <div className="transactions__transactions-table-container">
          <table id="closedTables" className="transactions__transactions-table table text-center">
            <thead>
              <tr>
                <th>Time</th>
                <th>Table</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {tables}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export { ClosedTables };