import React from 'react';
import { addPosReceiptItem } from './PosItemTile';

function ModalEnterAmount(props) {

  const { currentTable, tableList, setTableList, enterAmount, setEnterAmount } = props;

  const pecrentageList = [5, 10, 15, 20, 25].map((pecentage, index) => {
    return (
      <div key={index} data-id={index} onClick={() => submitAmount(pecentage)} className="col-lg-12 modal-pdq-link__container">
        <div className="modal-pdq-link">{pecentage}%</div>
      </div>
    )
  });

  async function submitAmount(percentage) {
    const id = enterAmount.id;
    const category = enterAmount.category;
    const name = `${percentage}% Discount`
    const currentTotal = tableList[currentTable -1].subTotal;
    const discount = Math.floor(currentTotal * percentage/100);
    addPosReceiptItem(currentTable, id, name, -discount, category, tableList, setTableList)
    resetModal();
  }

  function hideModal(e) {
    if (e.target.classList.contains("modal-enter-amount") || e.target.classList.contains("modal-background")) {
      resetModal();
    }
  }

  function resetModal() {
    enterAmount.isVisible = false;
    enterAmount.id = null;
    enterAmount.category = null;
    setEnterAmount({ ...enterAmount })
  }

  return (
    <div onClick={(e) => hideModal(e)} className={`modal-background ${enterAmount.isVisible === true ? "show" : ""}`}>
      <div className={`modal modal-enter-amount custom-modal fade bs-example-modal-sm ${enterAmount.isVisible === true ? "show" : ""}`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title" id="myModalLabel">Select discount amount</h4>
            </div>
            <div className="modal-body">
              <div className="row text-center" id="prds">
                {pecrentageList}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ModalEnterAmount }