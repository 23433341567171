import React from 'react';
import { RECEIPT_TYPE_ITEM } from './PosReceipt/components/PosReceiptItem';
import { removePriceFromTotals } from './PosButtons';

function PosItemRow(props) {

  const { currentTable, tableList, setTableList, posItems, enterAmount, setEnterAmount } = props;

  const posItemRow = posItems.map((posItem) =>
    <PosItemTile
      key={posItem.key}
      currentTable={currentTable}
      name={posItem.name}
      price={posItem.price}
      category={posItem.category}
      id={posItem.key}
      tableList={tableList}
      setTableList={setTableList}
      enterAmount={enterAmount}
      setEnterAmount={setEnterAmount}
    />
  )

  return (
    <>
      <div className="row mr-minus-10">
        {posItemRow}
      </div>
    </>
  );
}

function PosItemTile(props) {

  const { currentTable, id, name, price, category, tableList, setTableList, enterAmount, setEnterAmount } = props;
  const tableItems = tableList[currentTable - 1];
  const posItemsActive = tableItems.posItemsActive;
  const currentItem = tableItems.currentItem;

  function showDiscountModal() {
    if (eligibleForDiscount()) {
      enterAmount.isVisible = true;
      enterAmount.id = id;
      enterAmount.category = category;
      setEnterAmount({ ...enterAmount });
    }
  }

  function eligibleForDiscount() {
    return posItemsActive === true &&
      currentItem.index === -1 &&
      tableItems.items.length !== 0 &&
      tableItems.items.findIndex(x => x.category?.includes("discount")) === -1;
  }

  function addPosReceiptItemWrapper() {
    removeDiscountIfExists();
    addPosReceiptItem(currentTable, id, name, price, category, tableList, setTableList);
  }

  function removeDiscountIfExists() {
    const updatedTable = tableList[currentTable - 1];
    const discountIndex = updatedTable.items?.findIndex(x => x.category?.includes("discount"));
    if (discountIndex && discountIndex !== -1) {
      const itemToDelete = updatedTable.items[discountIndex];
      updatedTable.items.splice(discountIndex, 1);
      removePriceFromTotals(currentTable, updatedTable, itemToDelete.price)
      setTableList({...tableList});
    }
  }

  return (
    <>
      {name !== "discount" &&
        <div className="col-xs-4 products__product" onClick={() => addPosReceiptItemWrapper()}>
          <div className={`product-click-container ${posItemsActive === false || currentItem.index !== -1 ? "disabled-button" : ""}`}>
            <div className={`products__product__image-container ${name}`}>
            </div>
            <div className="products__product__text text-center">
              {name.charAt(0).toUpperCase() + name.substring(1)}
            </div>
          </div>
        </div>
      }
      {name === "discount" &&
        <div className="col-xs-4 products__product" onClick={() => showDiscountModal()}>
          <div className={`product-click-container ${!eligibleForDiscount() ? "disabled-button" : ""}`}>
            <div className={`products__product__image-container ${name}`}>
            </div>
            <div className="products__product__text text-center">
              {name.charAt(0).toUpperCase() + name.substring(1)}
            </div>
          </div>
        </div>
      }
    </>
    // <a href="https://www.flaticon.com/free-icons/burger" title="icons">Wine Glass, Vegan, Tomatos, Oat, Burger, Coconut, Chips icons created by Freepik - Flaticon</a>
    // <div> Icons made by <a href="" title="Icongeek26"> Icongeek26 </a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com'</a></div>
    // <a href="https://www.flaticon.com/free-icons/small" title="small icons">Small, medium, large, XL icons created by LAFS - Flaticon</a>
    // <a href="https://www.flaticon.com/free-icons/water-glass" title="water glass icons">Water glass icons created by Vignesh Oviyan - Flaticon</a>
    // <a href="https://www.flaticon.com/free-icons/glass" title="glass icons">Glass icons created by Nadiinko - Flaticon</a>
  );
}

function addPosReceiptItem(currentTable, id, name, price, category, tableList, setTableList) {
  const currentItem = tableList[currentTable - 1].currentItem;
  const posItemsActive = tableList[currentTable - 1].posItemsActive;

  if (posItemsActive && currentItem.index === -1) {
    const updatedTable = tableList[currentTable - 1];

    let existingItem = false;
    updatedTable.items.forEach((item, index) => {
      if (item.name === name && item.modifiers.length === 0) {
        updatedTable.items[index].quantity = updatedTable.items[index].quantity + 1;
        existingItem = true;
      }
    });
    if (!existingItem) {
      updatedTable.items.push({
        type: RECEIPT_TYPE_ITEM,
        name: name,
        quantity: 1,
        price: price,
        category: category,
        key: id,
        modifiers: [],
      })
    }
    updatedTable.subTotal += price;
    updatedTable.total += price;
    addItemWithServiceCharge(currentTable, updatedTable, price);
    setTableList([...tableList]);
  }
}

function addItemWithServiceCharge(currentTable, updatedTable, itemPrice) {
  if (currentTable === 5 || currentTable === 8) {
    const serviceCharge = Math.floor(itemPrice / 10);
    updatedTable.serviceCharge += serviceCharge;
    updatedTable.total += serviceCharge;
  }
}

export { PosItemRow, addPosReceiptItem };