import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { PosReceiptItem } from './components/PosReceiptItem';

function PosReceipt(props) {

  const { tableList, currentTable, modifiers, setTableList, clearPreviouslySelectedItemsFromArray } = props;

  const posItemsActive = tableList[currentTable - 1].posItemsActive;

  const posItemList = tableList[currentTable - 1].items.map((item, key) =>
    <PosReceiptItem
      key={key}
      index={key}
      name={item.name}
      quantity={item.quantity}
      price={item.price}
      category={item.category}
      currentTable={currentTable}
      modifiers={modifiers}
      tableList={tableList}
      setTableList={setTableList}
      posItemsActive={posItemsActive}
      clearPreviouslySelectedItemsFromArray={clearPreviouslySelectedItemsFromArray}
    />
  )

  return (
    <>
      <div className="col-xs-12 col-sm-5 col-md-5">
        <div className="row  ">
          <div className="receipt-before-zigzag">

          </div>
          <div id="receipt" className='receipt'>
            {posItemList}
          </div>
          <div className="receipt-after-zigzag">

          </div>
          <div className="payments">
            <div className="payments-row-container">
              <span className="payments__text">Sub-Total:</span>
              <span id="balance" className="payments__amount">
                <CurrencyFormat value={parseFloat(tableList[currentTable - 1].subTotal / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
              </span>
            </div>
            {[5, 8].includes(currentTable) &&
              <div className="payments-row-container">
                <span className="payments__text">Service:</span>
                <span id="balance" className="payments__amount">
                  <CurrencyFormat value={parseFloat(tableList[currentTable - 1].serviceCharge / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
                </span>
              </div>
            }
            <div className="payments-row-container">
              <span className="payments__text">Paid:</span>
              <span id="paymentsTotal" className="payments__amount">
                <CurrencyFormat value={parseFloat(tableList[currentTable - 1].paid / 100)} prefix={'-£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
              </span>
            </div>
            <div className="payments-row-container">
              <span className="payments__text">Total:</span>
              <span id="leftToPay" className="payments__amount">
                <CurrencyFormat value={parseFloat(tableList[currentTable - 1].total / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { PosReceipt };